import { useContext } from 'react';
import NextLink from 'next/link';
import { Link as NxLink, LinkProps as NxLinkProps } from '@nx-kit/link';
import get from 'lodash/get';
import linkResolver from 'prismic/helpers/linkResolver';
import { PrismicExternalLink, PrismicLink } from 'prismic/types';
import { DocumentRelationsContext } from 'contexts/documentRelations/DocumentRelationsContext';
import Icon from 'components/atoms/Icon/Icon';
import { IconType } from 'components/atoms/Icon/iconMap';
import { LOCALES } from 'prismic/config';
import { styled } from '@nx-kit/styling';

export interface LinkProps {
  text?: string | null;
  url?: PrismicLink | PrismicExternalLink;
  title?: string | null;
  anchor_id?: string | null;
  skin?: string;
  icon?: IconType;
  target?: string;
  className?: string;
  href?: string;
  showActive?: boolean;
  onPress?: () => boolean | void;
  children?: any;
}

export type NewLinkProps = NxLinkProps & {
  href?: string;
  title?: string;
  isActive?: boolean;
  isTargetBlank?: boolean;
  download?: boolean;
  onPress?: () => boolean | void;
};

const IconStyled = styled(Icon)`
  margin-left: 10px;
`;

export const ComposedLink = ({
  href,
  isTargetBlank = false,
  children,
  download,
  title,
  onPress,
  ...props
}: NewLinkProps) => {
  const isLink = !!href;
  const isExternalLink = isTargetBlank || href?.startsWith('http') || download;
  const changeLocale =
    isLink && !isExternalLink && href.split('/')[1] in LOCALES && href.split('/')[1];
  const handleClick = (e: any) => {
    if (onPress) {
      e.preventDefault();
      onPress();
    }
  };
  if (isLink) {
    if (isExternalLink) {
      return (
        <NxLink {...props}>
          <a
            href={href}
            title={title}
            target={isTargetBlank ? '_blank' : undefined}
            rel="noreferrer"
            download={download}
            onClick={handleClick}
          >
            {children}
          </a>
        </NxLink>
      );
    }

    return (
      // locale is necessary for next-translate
      <NextLink prefetch={false} href={href} locale={changeLocale} passHref legacyBehavior>
        <NxLink {...props}>
          <a title={title} onClick={handleClick}>
            {children}
          </a>
        </NxLink>
      </NextLink>
    );
  }

  return (
    <NxLink {...props}>
      <a onClick={handleClick}>{children}</a>
    </NxLink>
  );
};

const Link = ({ text, url, title, children, target, icon, onPress, ...rest }: LinkProps) => {
  const documentRelations = useContext(DocumentRelationsContext);

  if (!documentRelations) {
    return null;
  }

  return (
    <ComposedLink
      href={url ? linkResolver(url, documentRelations || undefined) : undefined}
      title={title || text || undefined}
      isTargetBlank={(target || get(url, 'target', '_self')) === '_blank'}
      onPress={onPress}
      {...rest}
    >
      {text || children}

      {(text || children) && icon && <IconStyled className="icon" name={icon} />}
      {!text && !children && icon && <Icon className="icon" name={icon} />}

      {rest.skin && String(rest.skin).startsWith('tertiary') && (
        <Icon className="arrow" name="ARROW" />
      )}
    </ComposedLink>
  );
};

export default Link;
